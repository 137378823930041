import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Gallery from "../components/gallery"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const post = data.markdownRemark

  return (
    <Layout title={siteTitle}>
      <SEO title="Modeling" keywords={[]} />

      <article className="post-content page-template no-image">
        <header className="post-content-header">
          <h1 className="post-content-title">Modeling</h1>
        </header>
        <p class="post-content-excerpt">
          Together, we will embody the creative process, identify artistic
          challenges and co-create a new and individualized definition of what
          it means to see and feel seen, manufacturing content by experiencing
          greater connection.
        </p>

        <div className="post-content-image">
          <Img
            className="kg-image"
            fluid={data.modelingcenter.childImageSharp.fluid}
            alt={post.frontmatter.title}
          />
        </div>

        <div className="post-content-body">
          <blockquote>
            <p>
              Seraphina intuitively knows what the shot needs. Very professional
              and super positive. She embodies her role and comes to life as
              whatever it is you require her to portray. A breath of fresh air.
              A pleasure to create with. I really look forward to our next
              shoot.
            </p>
          </blockquote>

          <p>
            Seraphina uses her passion for embodied practices and somatic arts
            to bring new meaning to the art of modeling, which has become one of
            her favorite ways to create and find connection through movement and
            the human form. With fifteen years of theater and acting experience
            in addition to her dance and movement background, she feels excited
            to design and adapt this artform to feel better for both subject and
            photographer. She is available to work with individuals, projects,
            and companies as well as for commercial, print, and film. In
            addition, Seraphina has previously worked as an art model at SVA
            (School of Visual Arts) in Manhattan and is available for figure
            work.
          </p>
          <p>
            Seraphina is also an agency signed model with State Management and
            can be hired through their Lifestyle Division by clicking{" "}
            <a href="https://www.statemgmt.com/new-york/lifestyle/women/all/1604454/seraphina">
              here
            </a>
            .
          </p>

          <div className="post-content-image">
            <Img
              className="kg-image"
              fluid={data.modelingbottom.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </div>

          <p>
            If you are looking to start modeling, or simply feel more
            comfortable and confident in front of the camera, Seraphina is also
            available for guidance. Seraphina began by working as a photographer
            and after studying both sides of the camera, cannot wait to help you
            see yourself as the beautiful and unique soul that you are. To learn
            more, send her an <a href="https://seraphina.nyc/#connect">email</a>
            .
          </p>

          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/28EX3RBSiVE"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BoatIgWDGpM"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/IM0Oy3pY9uI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <a href="https://www.youtube.com/embed/yfpvMlcb74c" target="_blank">
              <img src="/dream-catcher.png" />
            </a>
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/EhHDEyUyglI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/KIavD4cZwYo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="col-12 responsive-iframe-container"
            style={{ marginBottom: "1em" }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/NWuIaNFvO50"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>

          <h3>Stats:</h3>

          <ul>
            <li>AGE - 27</li>
            <li>HEIGHT 5'8''</li>
            <li>WAIST - 27 </li>
            <li>HIPS - 38 </li>
            <li>SHOE: 8.5</li>
            <li>CHEST - 34E </li>
            <li>EYE COLOR - Hazel </li>
            <li>HAIR COLOR - Brown </li>
            <li>HAIR LENGTH - Long </li>
          </ul>

          <p>
            For more portfolio work, you can find her model account on Instagram{" "}
            <a href="https://www.instagram.com/model.seraphina">
              @model.seraphina
            </a>
          </p>
        </div>
      </article>

      <Gallery
        images={data.modelingPics.edges.map(({ node }) => ({
          id: node.id,
          ...node.childImageSharp,
          caption: `Seraphina Models`,
        }))}
        itemsPerRow={[3]}
      />
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/modeling/" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    modelingcenter: file(relativePath: { eq: "modeling-center.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    modelingbottom: file(relativePath: { eq: "modeling-bottom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    modelingPics: allFile(
      filter: { absolutePath: { regex: "/modeling-pics/" } }
    ) {
      edges {
        node {
          id
          relativePath
          childImageSharp {
            id
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
              originalImg
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
