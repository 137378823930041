const carouselFormatters = {
  getAltText: ({ data, index }) => data.caption || `${index + 1} image`,

  getNextLabel: ({ currentIndex, views }) =>
    `Viewing image ${currentIndex + 2} of ${views.length}`,
  getPrevLabel: ({ currentIndex, views }) =>
    `Viewing image ${currentIndex} of ${views.length}`,

  getNextTitle: () => "Next (right arrow)",
  getPrevTitle: () => "Previous (left arrow)",

  getCloseLabel: () => "Close (esc)",
  getFullscreenLabel: ({ isFullscreen }) =>
    isFullscreen ? "Exit full screen (f)" : "Switch to full screen (f)",
}

export default carouselFormatters
